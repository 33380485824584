<template>
    <v-dialog v-model="show" width="500" class="clipper-dialog">
        <div class="photo_clipper">
            <button class="close_modal" @click="$emit('close')">
                <span class="close-icon"></span>
            </button>
            <clipper-fixed
                preview="avatarPreview"
                ref="clipper"
                :ratio="240/269"
                class="basic lg clipper-fixed"
                :src="image"
                bg-color="transparent"
                :round="false"
                :grid="false"
                shadow="rgba(0,0,0,0.8)"
                :area="50"
                :handle-zoom-event="handleZoom"
                @load="imgLoad"
                :rotate="0">
                <div slot="placeholder">No image</div>
            </clipper-fixed>

            <div class="slider-container">
                <span class="zoom-icon small"></span>
                <clipper-range v-model="scale" :min="0.5" :max="2" class="modern-range" stop-position-mouse></clipper-range>
                <span class="zoom-icon large"></span>
            </div>

            <div class="actions_clipper_photo">
                <button type="button" class="close_clipper_photo" @click="$emit('close')" v-ripple>Cancel</button>
                <button type="button" class="clip_photo primary_btn" @click="clipImage" v-ripple>Apply</button>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: "PhotoClipper",
    props: {
        show: {
            type: Boolean,
            required: true
        },
        image: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            scale: 0.5
        }
    },
    watch: {
        scale(val) {
            this.$refs.clipper.setWH$.next(+val);
        }
    },
    methods: {
        handleZoom(scale) {
            if (!isNaN(scale)) {
                const limitedScale = Math.max(Math.min(2, scale), 0.1);
                this.scale = limitedScale;
                return limitedScale;
            }
        },
        imgLoad() {
            this.scale = 0.5;
            this.$refs.clipper.setWH$.next(0.5);
        },
        clipImage() {
            const canvas = this.$refs.clipper.clip();
            const dataURL = canvas.toDataURL("image/png", 1);
            const newCacheKey = new Date().getTime();
            
            // Emit events in a specific order
            this.$emit('save', dataURL);
            this.$emit('update-cache-key', newCacheKey);
            this.$emit('image-updated', dataURL);
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss">
.clipper-dialog {
    .v-dialog {
        margin: 16px;
        
        // Fix for mobile
        @media (max-width: 480px) {
            margin: 8px;
            width: calc(100% - 16px) !important;
        }
    }
}

.photo_clipper {
    background-color: #0a0a0a;
    padding: clamp(16px, 5vw, 32px);
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.24);
    max-width: 100%;
    
    // Fix clipper container
    .clipper-container {
        width: 100% !important;
        max-width: 100% !important;
        
        .wrap {
            max-width: 100% !important;
            
            canvas {
                max-width: 100% !important;
                height: auto !important;
            }
        }
    }

    // Fix clipper area
    .clipper-fixed {
        border-radius: 12px;
        overflow: hidden;
        background-color: #0a0a0a;
        max-width: 100%;
        height: auto !important;
        aspect-ratio: 240/269;

        .wrap {
            max-width: 100% !important;
        }
        
        .img-scale {
            max-width: 100% !important;
        }
        
        .clip-area {
            border-radius: 12px !important;
        }
    }

    // Fix range slider
    .slider-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        margin: 24px auto;
        width: min(90%, 400px);
        padding: 0 8px;

        .modern-range {
            flex: 1;
            
            .wrap {
                height: 4px !important;
                background: rgba(255, 255, 255, 0.1) !important;
                border-radius: 4px !important;
            }

            .bar {
                background: #2563eb !important;
                height: 24px !important;
                width: 24px !important;
                border-radius: 50% !important;
                border: 2px solid #2563eb !important;
                background: white !important;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
                top: -10px !important;
                transition: transform 0.2s ease;
                cursor: pointer;
                transform-origin: center;
                margin-left: -12px !important;

                &:hover {
                    transform: scale(1.1);
                }

                &:active {
                    transform: scale(0.95);
                }
            }
        }
    }

    .actions_clipper_photo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: clamp(12px, 3vw, 16px);
        margin-top: clamp(16px, 4vw, 24px);

        button {
            min-width: min(140px, 45%);
            height: 44px;
            border-radius: 12px;
            font-weight: 500;
            font-size: clamp(14px, 3.5vw, 15px);
            transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
            padding: 0 clamp(16px, 4vw, 32px);
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.3px;

            @media (max-width: 480px) {
                height: 40px;
                border-radius: 8px;
            }

            &.close_clipper_photo {
                background-color: rgba(255, 255, 255, 0.05);
                border: none;
                color: #fff;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                    transform: translateY(-2px);
                }

                &:active {
                    transform: translateY(0);
                }
            }

            &.primary_btn, &.clip_photo {
                background-color: #2563eb;
                color: white;
                font-weight: 600;
                
                &:hover {
                    background-color: #1d4ed8;
                    transform: translateY(-2px);
                    box-shadow: 0 4px 12px rgba(37, 99, 235, 0.3);
                }

                &:active {
                    transform: translateY(0);
                    box-shadow: none;
                }
            }
        }
    }

    .close_modal {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.05);
        z-index: 1;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;

        .close-icon {
            position: relative;
            width: 16px;
            height: 16px;
            
            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: white;
                top: 50%;
                left: 0;
            }
            
            &::before {
                transform: rotate(45deg);
            }
            
            &::after {
                transform: rotate(-45deg);
            }
        }
        
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
            transform: scale(1.1);
        }
        
        &:active {
            transform: scale(0.95);
        }
    }
}

// Add Vuetify dialog overrides
.v-dialog__content {
    align-items: center;
    justify-content: center;
    
    .v-dialog {
        margin: 0;
    }
}
</style>
