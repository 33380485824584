export default {
    methods: {
        checkAccessToken() {
            let accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                return false;
            }
            return true;
        }
    }
}